import {
  Box,
  Button,
  Input,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Receipt,
  InvoicePayment,
  InvoiceState,
  Order,
  createNewInvoicePayment,
  generateNotification,
  updateInvoice,
  getPaymentsForInvoice,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import {
  convertExistingPaymentsIntoTableEntries,
  generateEmptyInvoicePayment,
  getTotalInvoiceSum,
} from "../../utils/invoice/Invoice.utils";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";

export const InvoicePaymentCreate: React.FC = () => {
  const navigate = useNavigate();
  const { axios, user } = useUser();
  const { t } = useTranslation();
  const location = useLocation<{ invoice?: Receipt; order?: Order }>();
  const [existingPayments, setExistingPayments] = useState<InvoicePayment[]>(
    []
  );
  const [invoiceToEdit, setInvoiceToEdit] = useState<Receipt | undefined>(
    location.state?.invoice
  );
  const [paymentToEdit, setPaymentToEdit] = useState<InvoicePayment>(
    generateEmptyInvoicePayment({
      invoiceId: location.state?.invoice?.id,
      orderId: location.state?.order?.id,
      customerId: location.state?.order?.customerId,
      createdBy: user.id,
    })
  );
  const { data: loadedUsers } = useData("SCHAMBECK_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const [showPartialPaymentPopup, toggleShowPaymentPopup] =
    useState<boolean>(false);

  /**
   *Hook to load all existing payments for the invoice
   */
  useEffect(() => {
    if (!location.state?.invoice?.id) return;
    getPaymentsForInvoice(axios, location.state.invoice.id).then(
      setExistingPayments
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.invoice?.id, paymentToEdit.id]);
  /**
   * Helper method to complete a payment with the full sum of an invoice
   */
  const handlePayment = (type: "partial" | "complete"): void => {
    if (!invoiceToEdit) return;
    const updatedInvoice: Receipt =
      type === "complete"
        ? {
            ...invoiceToEdit,
            invoiceState: InvoiceState.PAID,
          }
        : invoiceToEdit;

    const newPayment: InvoicePayment =
      type === "complete"
        ? {
            ...paymentToEdit,
            completePayment: true,
            amount: getTotalInvoiceSum(invoiceToEdit),
          }
        : paymentToEdit;

    Promise.all([
      updateInvoice(axios, updatedInvoice),
      createNewInvoicePayment(axios, newPayment),
    ]).then(([updatedInvoice, createdInvoicePayment]) => {
      if (updatedInvoice && createdInvoicePayment) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.createPayment"),
        });
        navigate("/accounting");
      }
    });
  };
  /**
   * Holds tableRows to display paments for the invoice
   */
  const paymentRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertExistingPaymentsIntoTableEntries(
        existingPayments,
        loadedUsers,
        setPaymentToEdit
      ),
    [existingPayments, loadedUsers]
  );
  return (
    <>
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t("pages.accounting.create.topBarHeadline")}
      >
        <Button
          type="button"
          value={t("general.buttons.completePayment")}
          onClick={() => handlePayment("complete")}
        />
        <Button
          type="button"
          onClick={() => toggleShowPaymentPopup(true)}
          value={t("general.buttons.partialPayment")}
        />
      </TopBar>
      <Popup
        isOpen={showPartialPaymentPopup}
        onClose={() => toggleShowPaymentPopup(false)}
        title={t("pages.customerUser.createOrEdit.partialPaymentPopupTitle")}
        buttons={[
          <Button
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => toggleShowPaymentPopup(false)}
          />,
          <Button
            type="button"
            value={t("general.buttons.save")}
            onClick={() => handlePayment("partial")}
          />,
        ]}
      >
        <p>{t("general.popup.partialPayment")}</p>
        <Input
          type="number"
          onChangeNumber={(amount) =>
            setPaymentToEdit({ ...paymentToEdit, amount })
          }
          value={paymentToEdit.amount}
        />
      </Popup>
      <Box title={t("pages.accounting.create.boxTitle")}>
        <div className="four-columns">
          <Input
            label={t("pages.accounting.create.paymentDate")}
            value={paymentToEdit.paymentDate}
            type="date"
            onChangeDate={(paymentDate) => {
              if (paymentDate)
                setPaymentToEdit({ ...paymentToEdit, paymentDate });
            }}
          />
          <Input
            type="number"
            value={invoiceToEdit?.dunningLevel}
            disabled
            onChangeNumber={() => {}}
            label={t("pages.accounting.create.dunningLevel")}
          />
          <Input
            disabled
            label={t("pages.accounting.create.createdBy")}
            type="text"
            value={getUserNameForSimpleUser(
              paymentToEdit.createdBy,
              loadedUsers
            )}
          />
          <TextArea
            resizable
            value={invoiceToEdit?.paymentNote || ""}
            label={t("pages.accounting.create.paymentNote")}
            onChange={(paymentNote) => {
              if (invoiceToEdit)
                setInvoiceToEdit({ ...invoiceToEdit, paymentNote });
            }}
          />
        </div>
      </Box>
      {existingPayments && (
        <Box title={t("pages.accounting.create.existingPayments")}>
          <Table
            rows={paymentRows}
            header={
              t("pages.accounting.create.tableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
          />
        </Box>
      )}
    </>
  );
};
