import { Comment } from "../comments/Comment.types";

/**
 * interface for cars with important technical data and userId if assigned
 */
export interface Car {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated: Date;
  updatedBy: string;
  userId: string;
  grossListPrice: number;
  enginePowerKw: number;
  registrationDate: Date;
  typeKey: TypeKey;
  registrationPlate: string;
  fuelType: FuelType;
  milage: number;
  brand: string;
  model: string;
  color: string;
  transmissionType: TransmissionType;
  officeId: string;
  seats: number;
  dealer: string;
  availableFrom: Date;
  restricted: boolean;
  restrictedReason: string;
  vehicleContractInformation: VehicleContractInformation;
  comments: Comment[];
  generalInspection: Date;
  type: CarType;
  disabled: boolean;
  poolUsage: boolean;
  vendor?: string;
  leasingNumber?: string;
  milageHistories: MilageHistory[];
}
/**
 * This model is used to track the development of the km amount on cars
 */
export interface MilageHistory {
  id: string;
  createdBy: string;
  createDate: Date;
  carId: string;
  previousMilage: number;
  currentMilage: number;
}

/**
 * Holds the differend types a car can be used for
 */
export enum CarType {
  UNKNOWN = "UNKNOWN",
  COMPANY = "COMPANY",
  RENTAL = "RENTAL",
  PRIVATE = "PRIVATE",
}

/**
 * Containing leasing and insurance information for a car
 */
export interface VehicleContractInformation {
  id: string;
  fuelCardStraehuber: string;
  pinStraehuber: string;
  pinDKV: string;
  fuelCardDKV: string;
  winterTyres: boolean;
  insurance: number;
  insuranceNumber: string;
  leasingRate: number;
  leasingNumber: string;
  priceKmOver: number;
  priceKmUnder: number;
  tax: number;
  leasingStart: Date;
  leasingEnd: Date;
  yearlyMilage: number;
  totalLeasingMilage: number;
}

/**
 * Interface for typeKeys (german hsn/tsn)
 */
export interface TypeKey {
  hsn: string;
  tsn: string;
}

/**
 * enum for different fuela types a car can have
 */
export enum FuelType {
  PETROL = "PETROL",
  DIESEL = "DIESEL",
  ELECTRIC = "ELECTRIC",
  HYBRID = "HYBRID",
  OTHER = "OTHER",
}

/**
 * enum for different transmissionTypes a car can have
 */
export enum TransmissionType {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC",
  UNKNOWN = "UNKNOWN",
}
