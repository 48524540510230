import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";
import { Car, Customer, generateEmptyCar, Order, SimpleUser } from "shared";
import {
  CustomerLocation,
  ShiftType,
} from "shared/src/customerLocation/CustomerLocation.types";
import {
  AppointmentState,
  Project,
  Schedule,
  ScheduleType,
} from "shared/src/project/Project.types";
import { Dropdown, Option } from "../..";
import { ReactComponent as DeleteIcon } from "../../../assets/cancel.svg";
import { ReactComponent as AddCarIcon } from "../../../assets/car_add.svg";
import { ReactComponent as AddUserIcon } from "../../../assets/person_add.svg";
import { ProjectPlanningTranslations } from "../ProjectPlanning.types";
import { getAbsentUserList } from "./PlanningBoard.util";

interface MainTableProps {
  startDate: Dayjs;
  projects: Project[];
  customers: Customer[];
  schedules: Schedule[];
  users: SimpleUser[];
  cars: Car[];
  translations: ProjectPlanningTranslations;
  isEdit: boolean;
  onScheduleUpdate(updatedSchedules: Schedule[]): void;
  customerLocations: CustomerLocation[];
  orders: Order[];
  checkWorkerAmount: (offerId: string, amount: number) => void;
}

/**
 * locally used enum to help differ which type of adjustment is done to the plan
 */
enum SelectionType {
  CAR = "CAR",
  USER = "USER",
}

export const MainTable: React.FC<MainTableProps> = ({
  startDate,
  projects,
  customers,
  schedules,
  users,
  cars,
  translations,
  isEdit,
  onScheduleUpdate,
  customerLocations,
  orders,
  checkWorkerAmount,
}) => {
  const [selectOptions, setSelectOptions] = useState<Option[]>();
  const [dropdownCell, setDropdownCell] = useState<string>();
  const [selectionType, setSelectionType] = useState<SelectionType>();

  /**
   * Helper to quickly reset all states that are set during edit mode enabling
   */
  const resetEditState = useCallback((): void => {
    setSelectOptions(undefined);
    setDropdownCell(undefined);
    setSelectionType(undefined);
  }, []);

  /**
   * Helper to get the available cars for the given day
   *
   * @param date The date to check for available cars
   */
  const getAvailableCars = useCallback(
    (date: dayjs.Dayjs): Car[] => {
      const filteredSchedules: Schedule[] = schedules.filter((schedule) =>
        date.isSame(dayjs(schedule.scheduleDate), "date")
      );
      if (filteredSchedules.length === 0) return cars;
      const scheduledCars: string[] = filteredSchedules
        .flatMap((schedule) => [...schedule.scheduledCars.values()])
        .flat();

      return cars.filter((car) => !scheduledCars.includes(car.id));
    },
    [cars, schedules]
  );

  /**
   * Helper to get the ids of the users which are absent on the given day
   *
   * @param date the date to check for
   */
  const getAbsentUsersIds = useCallback(
    (date: dayjs.Dayjs) => getAbsentUserList(schedules, date),
    [schedules]
  );

  /**
   * Callback method toget the ids of all scheduled users
   * @param date of the schedules to check
   * @returns array of userIds
   */
  const getScheduledUserIds = useCallback(
    (date: Dayjs): string[] => {
      const scheduledUsers: string[] = schedules
        .filter((schedule) => date.isSame(schedule.scheduleDate, "date"))
        .filter(
          (schedule) =>
            users.some((user) => user.id === schedule.referenceId) &&
            schedule.appointmentDetail?.state !== AppointmentState.AVAILABLE
        )
        .map((schedule) => schedule.referenceId);
      return scheduledUsers;
    },
    [schedules, users]
  );
  /**
   * Helper to extract the actual available users for the given day
   */
  const getAvailableUsers = useCallback(
    (date: dayjs.Dayjs): SimpleUser[] => {
      // check which user is absent
      const allAbsentUserIds: string[] = getAbsentUsersIds(date);
      const schedulesUserIds: string[] = getScheduledUserIds(date);
      return users.filter(
        (user) =>
          !allAbsentUserIds.includes(user.id) &&
          !schedulesUserIds.includes(user.id)
      );
    },
    [getAbsentUsersIds, getScheduledUserIds, users]
  );
  /**
   * Helper to generate the actual entry items in the daily shift entries
   */
  const addItemToShift = useCallback(
    (
      userId: string,
      project: Project,
      date: dayjs.Dayjs,
      shift: ShiftType
    ): void => {
      // check for the index of the schedule to update, -1 means that there is no schedule yet
      const scheduleIndexToUpdate: number = schedules.findIndex(
        (schedule) =>
          schedule.referenceId === project.id &&
          dayjs(schedule.scheduleDate).isSame(date, "date")
      );
      // generate some needed local variables
      const scheduleWorkingCopy: Schedule[] = structuredClone(schedules);
      const isCarUpdate: boolean = selectionType === SelectionType.CAR;
      // in case the update is a new shift entry simply generate a new shift
      if (scheduleIndexToUpdate < 0) {
        const newItemMap: Map<ShiftType, string[]> = new Map<
          ShiftType,
          string[]
        >();
        newItemMap.set(shift, [userId]);
        scheduleWorkingCopy.push({
          type: ScheduleType.PROJECT_SCHEDULE,
          id: undefined!,
          orderId: project.orderId,
          referenceId: project.id,
          scheduleDate: date.toDate(),
          scheduledUsers: isCarUpdate ? new Map() : newItemMap,
          scheduledCars: isCarUpdate ? newItemMap : new Map(),
        });
        // in this case there is a schedule entry for this project/date combination which can be updated
      } else {
        let scheduledItems: string[] = [];
        const updateField = isCarUpdate ? "scheduledCars" : "scheduledUsers";
        // check if there is already a shift entry
        if (schedules[scheduleIndexToUpdate][updateField].has(shift)) {
          scheduledItems = schedules[scheduleIndexToUpdate][updateField]
            .get(shift)!
            .concat(userId);
          checkWorkerAmount(project.acceptedOfferId, scheduledItems.length);
        } else {
          scheduledItems.push(userId);
        }
        // actually update the schedule work entry
        scheduleWorkingCopy[scheduleIndexToUpdate][updateField].set(
          shift,
          scheduledItems
        );
      }
      onScheduleUpdate(scheduleWorkingCopy);
      // reset the local states
      resetEditState();
    },
    [
      checkWorkerAmount,
      onScheduleUpdate,
      resetEditState,
      schedules,
      selectionType,
    ]
  );

  /**
   * Callback handler for the user click on an add icon for user or cars
   */
  const onAddClick = useCallback(
    (
      projectId: string,
      shift: ShiftType,
      date: dayjs.Dayjs,
      type: SelectionType
    ): void => {
      setSelectionType(type);
      setDropdownCell(`${projectId}__${shift}__${date.get("date")}`);
      if (type === SelectionType.USER) {
        setSelectOptions(() =>
          getAvailableUsers(date).map((user) => ({
            label: `${user.lastName} ${user.firstName.slice(0, 1)}`,
            value: user.id,
          }))
        );
      } else {
        setSelectOptions(() => [
          ...getAvailableCars(date).map((car) => ({
            label: `${car.registrationPlate}`,
            value: car.id,
          })),
          { label: translations.privateCar, value: "private-car" },
        ]);
      }
    },
    [getAvailableCars, getAvailableUsers, translations.privateCar]
  );

  /**
   * Helper method to handle deletion of a user entry
   * @param applicableSchedule schedule to update
   * @param shiftType type of the shift to update
   * @param user user to remove
   * @param date date of the entry
   */
  const handleDeleteUserEntry = useCallback(
    (
      applicableSchedule: Schedule,
      shiftType: ShiftType,
      user: SimpleUser,
      date: dayjs.Dayjs
    ): void => {
      const filteredScheduledUsers: string[] =
        applicableSchedule.scheduledUsers
          .get(shiftType)
          ?.filter((scheduledUser) => scheduledUser !== user.id) || [];

      applicableSchedule.scheduledUsers.set(shiftType, filteredScheduledUsers);

      const scheduleToUpdate: Map<ShiftType, string[]> =
        applicableSchedule.scheduledUsers;
      scheduleToUpdate.set(shiftType, filteredScheduledUsers);

      onScheduleUpdate(
        schedules.map((schedule) =>
          schedule.scheduledUsers.get(shiftType)?.includes(user.id) &&
          dayjs(schedule.scheduleDate).isSame(date)
            ? {
                ...schedule,
                scheduledUsers: scheduleToUpdate,
              }
            : schedule
        )
      );
    },
    [onScheduleUpdate, schedules]
  );

  /**
   * Helper function to temove a car of a project schedule
   * @param date date of the entry to remove
   * @param projectId id of the project to remove rhe cat
   * @param carId id of the car to remove
   * @param shift shift to remove the car from
   */
  const handleDeleteCar = useCallback(
    (
      applicableSchedule: Schedule,
      shift: ShiftType,
      date: dayjs.Dayjs,
      carId: string
    ): void => {
      const filteredScheduledCars: string[] =
        applicableSchedule.scheduledCars
          .get(shift)
          ?.filter((id) => id !== carId) || [];
      applicableSchedule.scheduledCars.set(shift, filteredScheduledCars);

      const scheduleToUpdate: Map<ShiftType, string[]> =
        applicableSchedule.scheduledCars;
      scheduleToUpdate.set(shift, filteredScheduledCars);

      onScheduleUpdate(
        schedules.map((schedule) =>
          schedule.scheduledCars.get(shift)?.includes(carId) &&
          dayjs(schedule.scheduleDate).isSame(date)
            ? {
                ...schedule,
                scheduledCars: scheduleToUpdate,
              }
            : schedule
        )
      );
    },
    [onScheduleUpdate, schedules]
  );
  /**
   * Util to generate the actual render elements for the individual days for one given shift
   *
   * @param project The project to generate entries for
   * @param shiftType The current looked at shift
   * @returns Render elements for all days for the given project and shift
   */
  const generateDayEntriesForProjectAndShift = useCallback(
    (project: Project, shiftType: ShiftType): JSX.Element[] => {
      const endDate: dayjs.Dayjs = startDate.add(4, "day");
      const targetArray: JSX.Element[] = [];
      for (
        let currDate = startDate;
        currDate.isSame(endDate) || currDate.isBefore(endDate);
        currDate = currDate.add(1, "day")
      ) {
        const applicableSchedule: Schedule | undefined = schedules.find(
          (schedule) =>
            schedule.referenceId === project.id &&
            dayjs(currDate).isSame(dayjs(schedule.scheduleDate), "D")
        );
        const dayEntries: JSX.Element[] = [];

        let scheduledUser: SimpleUser[] = [];
        // add all users first
        if (applicableSchedule?.scheduledUsers.get(shiftType)) {
          scheduledUser = applicableSchedule.scheduledUsers
            .get(shiftType)!
            .map((userId) => {
              const foundUser: SimpleUser | undefined = users.find(
                (u) => u.id === userId
              );
              if (!foundUser) return undefined!;
              return foundUser;
            });

          scheduledUser.forEach((user) => {
            if (!user) return;
            const schedulesForUser: Schedule[] = schedules.filter(
              (schedule) =>
                Array.from(schedule.scheduledUsers.values())
                  .flat()
                  ?.includes(user.id) &&
                currDate.isSame(schedule.scheduleDate, "date")
            );
            dayEntries.push(
              <div
                className={[
                  "project-planning__entry-row__swimlane__days__entry__name",
                  schedulesForUser.length > 1 ? "multi" : "",
                ].join(" ")}
              >
                {`${user.lastName}, ${user.firstName.slice(0, 1)}.`}
                {isEdit && (
                  <DeleteIcon
                    onClick={() => {
                      handleDeleteUserEntry(
                        applicableSchedule,
                        shiftType,
                        user,
                        currDate
                      );
                    }}
                  />
                )}
              </div>
            );
          });
        }

        // then add the cars
        applicableSchedule?.scheduledCars.get(shiftType) &&
          applicableSchedule.scheduledCars.get(shiftType)!.map((carId) => {
            const foundCar: Car | undefined =
              carId === "private-car"
                ? generateEmptyCar({
                    brand: "Private",
                    id: "private-car",
                    registrationPlate: translations.privateCar,
                  })
                : cars.find((c) => c.id === carId);
            if (!foundCar) return;
            else
              dayEntries.push(
                <div className="project-planning__entry-row__swimlane__days__entry__car">
                  {`${foundCar.registrationPlate}`}
                  {isEdit && (
                    <DeleteIcon
                      onClick={() =>
                        handleDeleteCar(
                          applicableSchedule,
                          shiftType,
                          currDate,
                          carId
                        )
                      }
                    />
                  )}
                </div>
              );
          });

        const entryHasDropdown: boolean =
          isEdit &&
          dropdownCell ===
            `${project.id}__${shiftType}__${currDate.get("date")}`;

        // put every entry in a day wrapper
        targetArray.push(
          <div
            className={`project-planning__entry-row__swimlane__days__entry  ${
              currDate.day() === 6 || currDate.day() === 0 ? "weekend-row" : ""
            }`}
          >
            {entryHasDropdown && (
              <Dropdown
                searchable
                autoFocus
                onChange={(selectedItem) =>
                  addItemToShift(selectedItem, project, currDate, shiftType)
                }
                options={selectOptions}
                defaultMenuIsOpen
                onClose={resetEditState}
              />
            )}
            {isEdit && !entryHasDropdown && (
              <div className="project-planning__entry-row__swimlane__days__entry__edit__wrapper">
                <div
                  className="project-planning__entry-row__swimlane__days__entry__edit__button"
                  onClick={() =>
                    onAddClick(
                      project.id,
                      shiftType,
                      currDate,
                      SelectionType.USER
                    )
                  }
                >
                  <AddUserIcon />
                </div>
                <div
                  className="project-planning__entry-row__swimlane__days__entry__edit__button"
                  onClick={() =>
                    onAddClick(
                      project.id,
                      shiftType,
                      currDate,
                      SelectionType.CAR
                    )
                  }
                >
                  <AddCarIcon />
                </div>
              </div>
            )}

            {dayEntries}
          </div>
        );
      }

      return targetArray;
    },
    [
      addItemToShift,
      cars,
      dropdownCell,
      handleDeleteCar,
      handleDeleteUserEntry,
      isEdit,
      onAddClick,
      resetEditState,
      schedules,
      selectOptions,
      startDate,
      users,
    ]
  );

  /**
   * Util to generate the swimlanes for every shift for the given project
   *
   * @param project The project to look at
   * @returns Render element for the project swimlanes
   */
  const generateSwimlanes = useCallback(
    (project: Project): JSX.Element => {
      const availableShifts: ShiftType[] =
        orders
          .find((order) => order.id === project.orderId)
          ?.shifts.map((shift) => shift.type) || [];
      return (
        <div className="project-planning__entry-row__swimlane__wrapper">
          {availableShifts
            .sort(
              (a, b) =>
                Object.keys(ShiftType).indexOf(a) -
                Object.keys(ShiftType).indexOf(b)
            )
            .map((shiftType) => (
              <div
                className={`project-planning__entry-row__swimlane__shift ${shiftType}`}
              >
                <div className="project-planning__entry-row__swimlane__title">
                  {translations.shifts[shiftType]}
                </div>
                <div className="project-planning__entry-row__swimlane__days__wrapper">
                  {generateDayEntriesForProjectAndShift(project, shiftType)}
                </div>
              </div>
            ))}
        </div>
      );
    },
    [generateDayEntriesForProjectAndShift, translations, orders]
  );

  return (
    <div className="project-planning__main-table__wrapper">
      {projects.map((project) => (
        <>
          <div className="project-planning__entry-row">
            <div className="project-planning__entry-row__customer">
              <p className="project-planning__entry-row__customer__order">
                {project.numberRangeNumber || "-"}
              </p>
              <p className="project-planning__entry-row__customer__name">
                {
                  customers.find(
                    (customer) => customer.id === project.customerId
                  )?.name
                }
              </p>
              <p className="project-planning__entry-row__customer__name">
                {customerLocations.find(
                  (location) => location.id === project.locationId
                )?.name || "-"}
              </p>
            </div>
            {generateSwimlanes(project)}
          </div>
          <div className="project-planning__entry-row__spacer" />
        </>
      ))}
    </div>
  );
};
